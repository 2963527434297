import Vue from 'vue';
import ContactResourceModel from '../../generated/types/model/resource/ContactResourceModel';

export default class ContactResourceViewModel extends ContactResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs
  get hasContactAddressValue() {
    let hasValue = false;
    // TODO: Search for method to get list of keys from interface
    const propsToCheck = ['salutation', 'firstName', 'lastName', 'dateOfBirth', 'eMail', 'street', 'houseNumber', 'addressLine1', 'postalCode', 'city', 'countryIso2', 'phoneNumber', 'countryIso2'];
    if (Vue._.isSet(this.address)) {
      Object.entries(this.address).forEach((item) => {
        if (propsToCheck.includes(item[0]) && Vue._.isSet(item[1])) {
          hasValue = true;
        }
      });
    }
    return hasValue;
  }
  get isOmContact():boolean { return this.dto.contactId !== undefined && this.dto.contactId !== null; }
}
