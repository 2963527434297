// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetMembershipTemplatesRequestModel from '../../model/get-membership-templates-request-model';
import GetMembershipTemplateByIdRequestModel from '../../model/get-membership-template-by-id-request-model';
import MembershipTemplateListResultModel from '../../model/membership-template-list-result-model';
import MembershipTemplateModel from '../../model/membership-template-model';

export default (service: Servicelayer) => ({
  /**  */
  async getMembershipTemplates(request: GetMembershipTemplatesRequestModel, config?: RequestConfig) {
    const requestDTO = GetMembershipTemplatesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-templates'] ?? 'Widget/MembershipTemplates';
    const response = await service.get<any>(endpointPath, {
      query: {
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipTemplateListResultModel.toModel(dto));
  },
  /**  */
  async getMembershipTemplateById(request: GetMembershipTemplateByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetMembershipTemplateByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-membership-template-by-id'] ?? 'Widget/MembershipTemplates/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MembershipTemplateModel.toModel(dto));
  },
});
