import { render, staticRenderFns } from "./prona-wizard-step.vue?vue&type=template&id=3896892c&scoped=true"
import script from "./prona-wizard-step.vue?vue&type=script&lang=ts"
export * from "./prona-wizard-step.vue?vue&type=script&lang=ts"
import style0 from "./prona-wizard-step.vue?vue&type=style&index=0&id=3896892c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3896892c",
  null
  
)

export default component.exports