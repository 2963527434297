// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactAddressResourceViewModel from '../../../../viewModel/resource/ContactAddressResourceViewModel';
import BillingAddressResourceViewModel from '../../../../viewModel/resource/BillingAddressResourceViewModel';
import BillingAddressResourceDTO from '../../dto/resource/BillingAddressResourceDTO';

export default abstract class BillingAddressResourceModel extends BaseModel<BillingAddressResourceDTO> {
  /**
  * @type {int32}
  */
  get customerNumber() { return this.dto.customerNumber; }
  set customerNumber(value) { this.dto.customerNumber = value; }
  /**
  * @type {int32}
  */
  get contactNumber() { return this.dto.contactNumber; }
  set contactNumber(value) { this.dto.contactNumber = value; }
  /**
  * @type {uuid}
  */
  get contactId() { return this.dto.contactId; }
  set contactId(value) { this.dto.contactId = value; }
  /**
  * @type {uuid}
  */
  get parentContactId() { return this.dto.parentContactId; }
  set parentContactId(value) { this.dto.parentContactId = value; }
  /**
  */
  get languageIso2() { return this.dto.languageIso2; }
  set languageIso2(value) { this.dto.languageIso2 = value; }
  /**
  */
  get address() { return this.getObjectModel(() => this.dto.address, ContactAddressResourceViewModel)!; }
  set address(value) { this.setObjectModel(() => this.dto.address, value); }
  /**
  */
  get isEqualToSchoolAddress() { return this.dto.isEqualToSchoolAddress; }
  set isEqualToSchoolAddress(value) { this.dto.isEqualToSchoolAddress = value; }
  /**
  */
  get billingAccount() { return this.dto.billingAccount; }
  set billingAccount(value) { this.dto.billingAccount = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.customernumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.customerNumber = value;
    value = Number.parseFloat(qs.contactnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.contactNumber = value;
    this.dto.contactId = qs.contactid;
    this.dto.parentContactId = qs.parentcontactid;
    this.dto.languageIso2 = qs.languageiso2;
    value = qs.isEqualToSchoolAddress === 'true' ? true : qs.isequaltoschooladdress;
    value = qs.isEqualToSchoolAddress === 'false' ? false : undefined;
    this.dto.isEqualToSchoolAddress = value;
    this.dto.billingAccount = qs.billingaccount;
  }
}
