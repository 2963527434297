import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {
  ...queryBuilder.properties({
    desiredDates: {
      type: 'string',
      required: true,
    },
    animationPlace: {
      type: 'string',
      required: true,
    },
    animationLanguageId: {
      type: 'string',
      required: true,
    },
    schoolClass: {
      type: 'string',
      required: true,
    },
    countParticipants: {
      type: 'string',
      required: true,
    },
    animationNotes: {
      type: 'string',
    },
  }),
};
