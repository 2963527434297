// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import CreateAnimaturaRegistrationRequestModel from '../../model/create-animatura-registration-request-model';
import LookupLanguagesRequestModel from '../../model/lookup-languages-request-model';
import GuidLookupListResultModel from '../../model/guid-lookup-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async createAnimaturaRegistration(request: CreateAnimaturaRegistrationRequestModel, config?: RequestConfig) {
    const requestDTO = CreateAnimaturaRegistrationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-animatura-registration'] ?? 'Widget/Animatura';
    const response = await service.post<any>(endpointPath, {
      body: {
        isAdminMode: requestDTO.isAdminMode,
        animationTopicKey: requestDTO.animationTopicKey,
        desiredDates: requestDTO.desiredDates,
        animationLanguageId: requestDTO.animationLanguageId,
        animationPlace: requestDTO.animationPlace,
        schoolClass: requestDTO.schoolClass,
        countParticipants: requestDTO.countParticipants,
        animationNotes: requestDTO.animationNotes,
        teacherContact: requestDTO.teacherContact,
        acceptMarketing: requestDTO.acceptMarketing,
        schoolContact: requestDTO.schoolContact,
        billingAddress: requestDTO.billingAddress,
        summary: requestDTO.summary,
        acceptGdpr: requestDTO.acceptGdpr,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async lookupLanguages(request: LookupLanguagesRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-lookup-languages'] ?? 'Widget/Animatura/languages/Lookup';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(GuidLookupListResultModel.toModel(dto));
  },
});
