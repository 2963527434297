import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  ...queryBuilder.properties({
    schoolContact: {
      ...queryBuilder.if({ isOmContact: false }),
      then: {
        ...queryBuilder.properties({
          address: {
            type: 'object',
            properties: {
              companyName: {
                type: 'string',
                required: true,
              },
              street: {
                type: 'string',
                required: true,
              },
              houseNumber: {
                type: 'string',
                required: true,
              },
              postalCode: {
                type: 'string',
                required: true,
              },
              city: {
                type: 'string',
                required: true,
              },
              phoneNumber: {
                type: 'string',
                pattern: validationPatterns.phone,
              },
            },
          },
        }),
      },
    },
    billingAddress: {
      properties: {

        billingAccount: {
          type: 'string',
        },
      },
      ...queryBuilder.if({ isEqualToSchoolAddress: false }),
      then: {
        ...queryBuilder.if({ isOmContact: false }),
        then: {
          ...queryBuilder.properties({
            address: {
              type: 'object',
              properties: {
                companyName: {
                  type: 'string',
                  required: true,
                },
                street: {
                  type: 'string',
                  required: true,
                },
                houseNumber: {
                  type: 'string',
                  required: true,
                },
                postalCode: {
                  type: 'string',
                  required: true,
                },
                city: {
                  type: 'string',
                  required: true,
                },
                eMail: {
                  type: 'string',
                  required: true,
                  pattern: validationPatterns.email,
                },
              },
            },
          }),
        },
        else: {
          ...queryBuilder.properties({
            address: {
              type: 'object',
              properties: {
                eMail: {
                  type: 'string',
                  required: true,
                  pattern: validationPatterns.email,
                },
              },
            },
          }),

        },
      },
    },
  }),
};
