// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactSchema from './contact-schema';
import BillingAddressSchema from './billing-address-schema';
import RequestSummarySchema from './request-summary-schema';

const schema = {
  $id: '/create-animatura-registration-request-schema',
  type: 'object',
  required: ['animationTopicKey'],
  properties: {
    isAdminMode: {
      type: 'boolean',
    },
    animationTopicKey: {
      type: 'integer',
    },
    desiredDates: {
      type: 'string',
      nullable: true,
    },
    animationLanguageId: {
      type: 'string',
      format: 'uuid',
    },
    animationPlace: {
      type: 'string',
      nullable: true,
    },
    schoolClass: {
      type: 'string',
      nullable: true,
    },
    countParticipants: {
      type: 'string',
      nullable: true,
    },
    animationNotes: {
      type: 'string',
      nullable: true,
    },
    teacherContact: {
      ...ContactSchema,
    },
    acceptMarketing: {
      type: 'boolean',
    },
    schoolContact: {
      ...ContactSchema,
    },
    billingAddress: {
      ...BillingAddressSchema,
    },
    summary: {
      ...RequestSummarySchema,
    },
    acceptGdpr: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
