import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import WidgetOMApi from '../generated/api/WidgetOM';

export default (service: Servicelayer) => ({
  ...WidgetOMApi(service),

  // Add custom calls here

});
