import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RecurringMembershipPaymentsApi from '../generated/api/RecurringMembershipPayments';

export default (service: Servicelayer) => ({
  ...RecurringMembershipPaymentsApi(service),

  // Add custom calls here

});
