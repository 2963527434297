<template>
  <prona-wizard-step class="wizard-step-animatura-order-overview" :proceed-label="$t('pronatura.widget.animaturawizard.summary.complete')" :proceed-disabled="true">
    <div>
      <h4 v-html="$t('pronatura.widget.animaturawizard.confirmation.subtitle')" />
    </div>
    <template #step-actions>
      <div></div>
    </template>
  </prona-wizard-step>
</template>

<script lang="ts">
  import AnimaturaTopicResourceViewModel from '@/src/services/v1/viewModel/resource/AnimaturaTopicResourceViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardStepAnimaturaOverview',
    props: {
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      selectedMembership: undefined as undefined |AnimaturaTopicResourceViewModel,
    }),
  });
</script>
