<template>
  <div>
    <p-progress-linear v-if="vm.isLoading" :indeterminate="true"></p-progress-linear>
    <p-request-alert v-model="vm.error"></p-request-alert>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import WalleeWidgetComponent from './prona-wallee-widget.vue.model';

  export default Vue.extend({
    props: {
      show: { type: Boolean, default: false },
      initialize: { type: Boolean, default: false },
      transactionId: { type: String, default: undefined },
      successUrl: { type: String, default: undefined },
      failedUrl: { type: String, default: undefined },
    },
    data: () => ({
      vm: new WalleeWidgetComponent(),
    }),
    watch: {
      show: {
        immediate: true,
        handler() {
          if (this.show) {
            this.vm.showWalleeWidget();
          }
        },
      },
      initialize: {
        immediate: true,
        handler() {
          if (this.initialize) {
            this.vm.initializeWalleeWidget(this.transactionId, this.successUrl, this.failedUrl);
          }
        },
      },
    },
    mounted() {
      this.vm.initialize();
    },
  });
</script>
