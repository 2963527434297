// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetAnimaturaTopicsRequestModel from '../../model/get-animatura-topics-request-model';
import GetAnimaturaTopicsRequestDTO from '../dto/get-animatura-topics-request-dto';
import dtoSchema from '../schemas/get-animatura-topics-request-schema';

export default abstract class GetAnimaturaTopicsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetAnimaturaTopicsRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetAnimaturaTopicsRequestDTO>): GetAnimaturaTopicsRequestModel;
  static toModel(dto: DeepPartial<GetAnimaturaTopicsRequestDTO> | undefined | null): GetAnimaturaTopicsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetAnimaturaTopicsRequestDTO> | undefined | null): GetAnimaturaTopicsRequestModel | undefined {
    return dto ? new GetAnimaturaTopicsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetAnimaturaTopicsRequestModel> | GetAnimaturaTopicsRequestModel): GetAnimaturaTopicsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      searchCriteria: unwrapped.searchCriteria,
    } as GetAnimaturaTopicsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetAnimaturaTopicsRequestModel, can not map to GetAnimaturaTopicsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
