import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import Logger from '@glittr/frontend-core/src/plugins/logging/logger';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import UploadRequestDTO from '../../../../services/v2/_generated/dto/upload-request-dto';
import UploadRequestModel from '../../../../services/v2/model/upload-request-model';

export default class RecurringMembershipPaymentsComponent extends ViewModelBase {
  constructor(private $service: Servicelayer, private $log:Logger) { super(); }
  async initialize(): Promise<void> {
    this.isLoading = false;
  }
  error?: any = undefined;
  isLoading: boolean = false;
  isProcessing: boolean = false;
  enableShowCalls: boolean = false;
  processStarTime = new Date();
  file = [];
  async processFile(file:never[]) {
    try {
      this.error = undefined;
      this.isLoading = true;
      this.isProcessing = true;
      if (file.length === 1) {
        // Call Backend to process file
        this.processStarTime = new Date();
        const req = new UploadRequestModel({ formFile: file[0] as File } as DeepPartial<UploadRequestDTO>);
        const result = await this.$service.v2.api.recurringMembershipPayments.upload(req);
        this.enableShowCalls = result.data > 0;
        this.file = [];
        return result;
      }
      this.error = new Error('No file selected');
      return Promise.resolve(0);
    } catch (error) {
      this.$log.error(`Unable to processFile file ${file}.`);
      this.$log.error(error);
      this.error = error;
      return Promise.resolve(0);
    } finally {
      this.isProcessing = false;
      this.isLoading = false;
    }
  }
}
