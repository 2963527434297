<template>
  <prona-wizard-step class="wizard-step-animatura-excursiondata" :custom-proceed-function="customProceed" :errors="validationError">
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div class="pronatura-animatura-form">
      <p-row v-if="wizardData.isAdminMode">
        <p-col xs10 md6>
          <p-text-field v-model="contactSearchString" label="OM Number" :disabled="isContactFromTeacher" clearable @clear="clearSchoolContacts()"></p-text-field>
        </p-col>
        <p-col xs2 md6 align-end>
          <p-button
            secondary
            :disabled="!searchContactIsValid(contactSearchString)"
            :loading="contactSearchSearching"
            @click="searchContact(contactSearchString)"
          >
            {{ $t('app.search') }}
          </p-button>
        </p-col>
      </p-row>
      <p-row v-if="wizardData.isAdminMode && contactSearchError!==undefined">
        <p-col>
          <p-alert value="true" type="error">
            {{ contactSearchError }}
          </p-alert>
        </p-col>
      </p-row>
      <p-row v-if="wizardData.isAdminMode">
        <p-col>
          <hr />
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md7>
          <p-text-field v-model="wizardData.schoolContact.address.companyName" :disabled="wizardData.schoolContact.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.school')" required></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md10>
          <p-text-field v-model="wizardData.schoolContact.address.street" :disabled="wizardData.schoolContact.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.street')" required></p-text-field>
        </p-col>
        <p-col xs12 md2>
          <p-text-field v-model="wizardData.schoolContact.address.houseNumber" :disabled="wizardData.schoolContact.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.housenumber')" required></p-text-field>
        </p-col>
      </p-row>
      <p-city-field v-model="wizardData.schoolContact.address" :disabled="wizardData.schoolContact.contactId!=undefined" required></p-city-field>
      <p-row>
        <p-col xs12>
          <p-phone-field v-model="wizardData.schoolContact.address.phoneNumber" :disabled="wizardData.schoolContact.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.phonenumber')"></p-phone-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-checkbox v-model="wizardData.billingAddress.isEqualToSchoolAddress" :label="$t('pronatura.widget.animaturawizard.school.sameBillingAddress')"></p-checkbox>
        </p-col>
      </p-row>
      <div v-if="!wizardData.billingAddress.isEqualToSchoolAddress">
        <p-row>
          <p-col>
            <h3>{{ $t('pronatura.widget.animaturawizard.school.billingAddress.title') }}</h3>
          </p-col>
        </p-row>
        <p-row v-if="wizardData.isAdminMode">
          <p-col xs10 md6>
            <p-text-field v-model="billingContactSearchString" label="OM Number" clearable @clear="clearBillingContacts()"></p-text-field>
          </p-col>
          <p-col xs2 md6 align-end>
            <p-button
              secondary
              :disabled="!searchContactIsValid(billingContactSearchString)"
              :loading="billingContactSearching"
              @click="searchBillingContact(billingContactSearchString)"
            >
              {{ $t('app.search') }}
            </p-button>
          </p-col>
        </p-row>
        <p-row v-if="wizardData.isAdminMode && billingContactSearchError!==undefined">
          <p-col>
            <p-alert value="true" type="error">
              {{ billingContactSearchError }}
            </p-alert>
          </p-col>
        </p-row>
        <p-row v-if="wizardData.isAdminMode">
          <p-col>
            <hr />
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12 md7>
            <p-text-field v-model="wizardData.billingAddress.address.companyName" :disabled="wizardData.billingAddress.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.billingAddress')" required></p-text-field>
          </p-col>
        </p-row>
        <p-row>
          <p-col xs12 md10>
            <p-text-field v-model="wizardData.billingAddress.address.street" :disabled="wizardData.billingAddress.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.street')" required></p-text-field>
          </p-col>
          <p-col xs12 md2>
            <p-text-field v-model="wizardData.billingAddress.address.houseNumber" :disabled="wizardData.billingAddress.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.housenumber')" required></p-text-field>
          </p-col>
        </p-row>
        <p-city-field v-model="wizardData.billingAddress.address" :disabled="wizardData.billingAddress.contactId!=undefined" required></p-city-field>
        <p-row>
          <p-col xs12 md7>
            <p-text-field v-model="wizardData.billingAddress.address.eMail" :disabled="wizardData.billingAddress.contactId!=undefined" :label="$t('pronatura.widget.animaturawizard.school.eMail')" required></p-text-field>
          </p-col>
        </p-row>
      </div>
      <p-row>
        <p-col xs12 md12>
          <p-text-field v-model="wizardData.billingAddress.billingAccount" :label="$t('pronatura.widget.animaturawizard.school.accountAssignment')"></p-text-field>
        </p-col>
      </p-row>
    </div>
  </prona-wizard-step>
</template>

<script lang="ts">
  import AnimaturaRegistrationRequestViewModel from '@/src/services/v1/viewModel/resource/AnimaturaRegistrationRequestViewModel';
  import BillingAddressResourceViewModel from '@/src/services/v1/viewModel/resource/BillingAddressResourceViewModel';
  import ContactAddressResourceViewModel from '@/src/services/v1/viewModel/resource/ContactAddressResourceViewModel';
  import ContactResourceViewModel from '@/src/services/v1/viewModel/resource/ContactResourceViewModel';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'WizardStepTeacherData',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      contactSearchError: undefined as string | undefined,
      contactSearchSearching: false as boolean,
      contactSearchString: '' as string,
      billingContactSearchError: undefined as string | undefined,
      billingContactSearching: false as boolean,
      billingContactSearchString: '' as string,
      validationError: undefined as string | undefined,
    }),
    computed: {
      isContactFromTeacher: {
        get(): boolean {
          return this.wizardData.teacherContact.customerNumber !== undefined && this.wizardData.teacherContact.customerNumber === this.wizardData.schoolContact.customerNumber;
        },
      },
      wizardData: {
        get(): AnimaturaRegistrationRequestViewModel {
          return this.value ?? {};
        },
        set(value: AnimaturaRegistrationRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    beforeMount() {
      if (this.wizardData.schoolContact == null) {
        this.wizardData.schoolContact = new ContactResourceViewModel().fromModel({ address: new ContactAddressResourceViewModel().fromModel({}) });
      }
      if (this.wizardData.schoolContact.address == null) {
        this.wizardData.schoolContact.address = new ContactAddressResourceViewModel().fromModel({});
      }
      if (this.wizardData.billingAddress == null) {
        this.wizardData.billingAddress = new BillingAddressResourceViewModel().fromModel({});
      }
      if (this.wizardData.billingAddress.address == null) {
        this.wizardData.billingAddress.address = new ContactAddressResourceViewModel().fromModel({});
      }
      if (this.wizardData.schoolContact.customerNumber !== undefined) {
        this.contactSearchString = `${this.wizardData.schoolContact.customerNumber}-${this.wizardData.schoolContact.contactNumber}`;
      }
      if (this.wizardData.billingAddress.customerNumber !== undefined) {
        this.billingContactSearchString = `${this.wizardData.billingAddress.customerNumber}-${this.wizardData.billingAddress.contactNumber}`;
      }
    },
    methods: {
      validate(): boolean {
        if (!this.wizardData.billingAddress.isEqualToSchoolAddress && this.wizardData.billingAddress.address.eMail?.toLowerCase().trim() === this.wizardData.teacherContact.address.eMail?.toLowerCase().trim()) {
          this.validationError = this.$t('pronatura.widget.animaturawizard.schoolemailsameasteacher');
          return false;
        }
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.proceedFunction!();
        }
      },
      async clearSchoolContacts() {
        this.wizardData.schoolContact = new ContactResourceViewModel().fromModel({ address: new ContactAddressResourceViewModel().fromModel({}) });
      },
      async clearBillingContacts() {
        this.wizardData.billingAddress = new BillingAddressResourceViewModel().fromModel({ });
      },

      searchContactIsValid(searchstring: string) :boolean {
        return (/^\d{5,9}-\d{1,6}$/.test(searchstring));
      },
      async searchContact(searchstring: string) {
        this.contactSearchError = undefined;
        if (this.searchContactIsValid(searchstring)) {
          this.contactSearchSearching = true;
          const paramArray = searchstring.split('-');
          try {
            const contact = await this.$service.v1.api.widgetOM.contact(+paramArray[0], +paramArray[1]);
            if (contact !== null && contact?.customerNumber) {
              this.wizardData.schoolContact = contact;
            } else {
              this.contactSearchError = this.$t('pronatura.widget.animaturawizard.om.contact.not.found');
            }
          } catch (error:any) {
            this.contactSearchError = error?.data.result ?? error.message;
          } finally {
            this.contactSearchSearching = false;
          }
        } else {
          this.contactSearchError = this.$t('pronatura.widget.animaturawizard.om.wrong.format');
        }
        this.contactSearchSearching = false;
      },
      async searchBillingContact(searchstring: string) {
        this.billingContactSearchError = undefined;
        if (this.searchContactIsValid(searchstring)) {
          this.billingContactSearching = true;
          const paramArray = searchstring.split('-');
          try {
            const contact = await this.$service.v1.api.widgetOM.contact(+paramArray[0], +paramArray[1]);
            if (contact !== null && contact?.customerNumber) {
              if ((`${contact.address.eMail}`).length === 0) {
                this.billingContactSearchError = this.$t('pronatura.widget.animaturawizard.om.contact.no.eMail');
              } else {
                this.wizardData.billingAddress = new BillingAddressResourceViewModel().fromModel(contact);
                this.wizardData.billingAddress.isEqualToSchoolAddress = false;
              }
            } else {
              this.billingContactSearchError = this.$t('pronatura.widget.animaturawizard.om.contact.not.found');
            }
          } catch (error:any) {
            this.billingContactSearchError = error?.data.result ?? error.message;
          } finally {
            this.billingContactSearching = false;
          }
        } else {
          this.billingContactSearchError = this.$t('pronatura.widget.animaturawizard.om.wrong.format');
        }
        this.billingContactSearching = false;
      },
    },

  });
</script>
