import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import WidgetMembershipsApi from '../generated/api/WidgetMemberships';

export default (service: Servicelayer) => ({
  ...WidgetMembershipsApi(service),

  // Add custom calls here

});
