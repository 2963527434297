// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ContactRequestModel from '../../model/contact-request-model';
import ContactRequestDTO from '../dto/contact-request-dto';
import dtoSchema from '../schemas/contact-request-schema';

export default abstract class ContactRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ContactRequestDTO>) {
    super();
    if (data) {
      this.customerNumber = data.customerNumber;
      this.contactNumber = data.contactNumber;
    }
  }
  /**
  * @type {int64}
  */
  customerNumber?: number;
  /**
  * @type {int64}
  */
  contactNumber?: number;

  static toModel(dto: DeepPartial<ContactRequestDTO>): ContactRequestModel;
  static toModel(dto: DeepPartial<ContactRequestDTO> | undefined | null): ContactRequestModel | undefined;
  static toModel(dto: DeepPartial<ContactRequestDTO> | undefined | null): ContactRequestModel | undefined {
    return dto ? new ContactRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ContactRequestModel> | ContactRequestModel): ContactRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      customerNumber: unwrapped.customerNumber,
      contactNumber: unwrapped.contactNumber,
    } as ContactRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ContactRequestModel, can not map to ContactRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
