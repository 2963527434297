// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import ContactResourceViewModel from '../../viewModel/resource/ContactResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async contact(customerNumber: number, contactNumber: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-contact'] ?? 'Widget/OM/Contact/{customerNumber}/{contactNumber}';
    endpointPath = endpointPath.replace(/\{customerNumber\}/g, `${customerNumber}`);
    endpointPath = endpointPath.replace(/\{contactNumber\}/g, `${contactNumber}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ContactResourceViewModel().fromDTO(dto);
  },
  /**  */
  async contactById(objectId: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-contact-by-id'] ?? 'Widget/OM/Contact/{objectId}';
    endpointPath = endpointPath.replace(/\{objectId\}/g, `${objectId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new ContactResourceViewModel().fromDTO(dto);
  },
});
