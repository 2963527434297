<template>
  <prona-wizard-step class="wizard-step-membership-partner-contactdata">
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div class="pronatura-membership-form">
      <p-row>
        <p-col xs12 md5>
          <!-- WO IN WIZARD DATA EINTRAGEN?? NICHT IN MEMBER CONTACT, SONDERN MÜSSTE GIFT CONTACT OÄ HABEN-->
          <p-select v-model="wizardData.partnerContact.address.salutation" :label="$t('core.address.salutation')" :items="salutationSelectItems" clearable :required="wizardData.partnerContact.hasContactAddressValue"></p-select>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md6>
          <p-text-field v-model="wizardData.partnerContact.address.firstName" :label="$t('pronatura.widget.membershipwizard.customer_first_name')" :required="wizardData.partnerContact.hasContactAddressValue"></p-text-field>
        </p-col>
        <p-col xs12 md6>
          <p-text-field v-model="wizardData.partnerContact.address.lastName" :label="$t('pronatura.widget.membershipwizard.customer_last_name')" :required="wizardData.partnerContact.hasContactAddressValue"></p-text-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12 md5>
          <p-date-field v-model="wizardData.partnerContact.address.dateOfBirth" :label="$t('pronatura.widget.membershipwizard.customer_dob')" :placeholder="$format.localDate($date.now())" :max="wizardData.partnerContact.address.dateOfBirthMax"></p-date-field>
        </p-col>
      </p-row>
      <p-row>
        <p-col xs12>
          <p-text-field v-model="wizardData.partnerContact.address.eMail" :label="$t('pronatura.widget.membershipwizard.customer_email')"></p-text-field>
        </p-col>
      </p-row>
    </div>
  </prona-wizard-step>
</template>

<script lang="ts">
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'WizardStepMembershipPartnerContactdata',
    props: {
      value: { type: Object, default: undefined },
    },
    data: () => ({
      isLoading: false,
      salutationSelectItems: [
        { caption: Vue.$t('core.address.salutationEnum1'), id: 1 },
        { caption: Vue.$t('core.address.salutationEnum2'), id: 2 },
        { caption: Vue.$t('core.address.salutationEnum3'), id: 3 },
      ],
      error: undefined as any,
    }),
    computed: {
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.value ?? {};
        },
        set(value: CreateMembershipRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
  });
</script>
