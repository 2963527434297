import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  ...queryBuilder.properties({
    teacherContact: {
      ...queryBuilder.if({ isOmContact: false }),
      then: {
        ...queryBuilder.properties({
          languageIso2: {
            required: true,
          },
          address: {
            type: 'object',
            properties: {
              salutation: {
                required: true,
              },
              firstName: {
                type: 'string',
                required: true,
              },
              lastName: {
                type: 'string',
                required: true,
              },
              mobilePhoneNumber: {
                type: 'string',
                required: true,
                pattern: validationPatterns.phone,
              },
              eMail: {
                type: 'string',
                required: true,
                pattern: validationPatterns.email,
              },
            },
          },
        }),
      },
    },
  }),
};
