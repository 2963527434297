import Vue from 'vue';
import ContactAddressResourceModel from '../../generated/types/model/resource/ContactAddressResourceModel';

export default class ContactAddressResourceViewModel extends ContactAddressResourceModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs
  get dateOfBirthMax() {
    return Vue.$date.now().format('YYYY-MM-DD');
  }
}
