<template>
  <p-container>
    <p-row column>
      <p-col>
        <h2>{{ $t('page.recurring_membership_payments.name') }}</h2>
      </p-col>
      <p-request-alert v-model="model.error"></p-request-alert>
      <p-progress-linear v-if="model.isProcessing" class="my-5" indeterminate></p-progress-linear>
      <p-col>
        <p-file-drop
          v-model="model.file"
          :label="$t('page.recurring_membership_payments.uploadfile.label')"
          :loading="model.isLoading"
          max="1"
          :single="true"
          accept="text/xml"
          @add-file="model.enableShowCalls=false"
        ></p-file-drop>
      </p-col>
      <p-col justify-end>
        <router-link v-if="model.enableShowCalls" :to="`/_admin/integrationlog?integrationtaskid=13&datefrom=${(model.processStarTime).toISOString()}&paging.currentpage=1&paging.pagesize=10&paging.includecount=true`" tag="p-button">
          <p-icon left>
            manage_search
          </p-icon>{{ $t('page.recurring_membership_payments.action.showLog') }}
        </router-link>
        <p-button :disabled="model.file.length===0 || model.isLoading || model.enableShowCalls" :loading="model.isLoading" class="mr-3" secondary @click="model.processFile(model.file)">
          <p-icon left>
            upload_file
          </p-icon>{{ $t('page.recurring_membership_payments.action.upload') }}
        </p-button>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import pFileDrop from '@glittr/frontend-core/src/components/p-file-drop/p-file-drop.vue';
  import Vue from 'vue';
  import RecurringMembershipPaymentsComponent from './recurring-membership-payments.vue.model';

  export default Vue.extend({
    auth: true,
    components: { pFileDrop },
    data: () => ({
      model: new RecurringMembershipPaymentsComponent(Vue.$service, Vue.$log),
    }),

  });
</script>
