// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import BillingAddressModel from '../../model/billing-address-model';
import BillingAddressDTO from '../dto/billing-address-dto';
import dtoSchema from '../schemas/billing-address-schema';
import ContactAddressModel from '../../model/contact-address-model';

export default abstract class BillingAddressModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<BillingAddressDTO>) {
    super();
    if (data) {
      this.customerNumber = data.customerNumber ?? undefined;
      this.contactNumber = data.contactNumber ?? undefined;
      this.contactId = data.contactId ?? undefined;
      this.parentContactId = data.parentContactId ?? undefined;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.address = data.address ? ContactAddressModel.toModel(data.address) : undefined;
      this.isEqualToSchoolAddress = data.isEqualToSchoolAddress;
      this.billingAccount = data.billingAccount ?? undefined;
    }
  }
  /**
  * @type {int32}
  */
  customerNumber?: number;
  /**
  * @type {int32}
  */
  contactNumber?: number;
  /**
  * @type {uuid}
  */
  contactId?: string;
  /**
  * @type {uuid}
  */
  parentContactId?: string;
  /**
  */
  languageIso2?: string;
  /**
  */
  address?: ContactAddressModel;
  /**
  */
  isEqualToSchoolAddress?: boolean;
  /**
  */
  billingAccount?: string;

  static toModel(dto: DeepPartial<BillingAddressDTO>): BillingAddressModel;
  static toModel(dto: DeepPartial<BillingAddressDTO> | undefined | null): BillingAddressModel | undefined;
  static toModel(dto: DeepPartial<BillingAddressDTO> | undefined | null): BillingAddressModel | undefined {
    return dto ? new BillingAddressModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<BillingAddressModel> | BillingAddressModel): BillingAddressDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      customerNumber: unwrapped.customerNumber,
      contactNumber: unwrapped.contactNumber,
      contactId: unwrapped.contactId,
      parentContactId: unwrapped.parentContactId,
      languageIso2: unwrapped.languageIso2,
      address: unwrapped.address,
      isEqualToSchoolAddress: unwrapped.isEqualToSchoolAddress,
      billingAccount: unwrapped.billingAccount,
    } as BillingAddressDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for BillingAddressModel, can not map to BillingAddressDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
