import Vue from 'vue';
import ViewModelBase from '@glittr/frontend-core/src/core/v2/app/viewModelBase';
import GetWidgetJavaScriptUrlRequestModel from '../services/v2/model/get-widget-java-script-url-request-model';

export default class WalleeWidgetComponent extends ViewModelBase {
  error?: any = undefined;
  isLoading: boolean = false;

  private initializeWalleeWidgetCalled: boolean = false;
  private showWalleeWidgetCalled: boolean = false;
  private showAfterScriptLoaded: boolean = false;

  async initialize(): Promise<void> {
    this.refreshDataSources();
  }

  refreshDataSources() {
  }

  async initializeWalleeWidget(transactionId: string, successUrl: string, failedUrl: string): Promise<void> {
    if (this.initializeWalleeWidgetCalled) {
      return;
    }
    this.initializeWalleeWidgetCalled = true;
    try {
      const jsUrl = await this.getJsUrl(transactionId, successUrl, failedUrl);
      const script = document.createElement('script');
      script.src = jsUrl;
      script.addEventListener('load', () => {
        if (this.showAfterScriptLoaded) {
          this.showWalleeWidget();
        }
      });
      document.head.appendChild(script);
    } catch (error) {
      this.error = error;
      this.isLoading = false;
    }
  }

  showWalleeWidget(): void {
    if (this.showWalleeWidgetCalled) {
      return;
    }
    this.isLoading = true;
    const WalleeWindow = window as any;
    if (!WalleeWindow.LightboxCheckoutHandler) {
      // The wallee script has not yet finished initializing
      // The onload event listener will call this method again
      this.showAfterScriptLoaded = true;
      return;
    }
    this.showWalleeWidgetCalled = true;
    try {
      WalleeWindow.LightboxCheckoutHandler.startPayment();
    } catch (error) {
      this.error = error;
    }
    this.isLoading = false;
  }

  private async getJsUrl(transactionId: string, successUrl: string, failedUrl: string): Promise<string> {
    const req = new GetWidgetJavaScriptUrlRequestModel();
    req.id = transactionId;
    req.successUrl = this.toAbsoluteUrl(successUrl);
    req.failedUrl = this.toAbsoluteUrl(failedUrl);
    const resp = await Vue.$service.v2.api.onlinePaymentTransaction.getWidgetJavaScriptUrl(req);
    if (!resp.data) {
      throw new Error('No Widget Javascript Url was returned');
    }
    return resp.data;
  }

  private toAbsoluteUrl(url: string): string {
    return Vue.$routerUtils.isUrl(url) ? url : `${window.location.origin}${url}`;
  }
}
