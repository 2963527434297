// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ContactAddressModel from '../../model/contact-address-model';
import ContactAddressDTO from '../dto/contact-address-dto';
import dtoSchema from '../schemas/contact-address-schema';

export default abstract class ContactAddressModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ContactAddressDTO>) {
    super();
    if (data) {
      this.isCompany = data.isCompany;
      this.companyName = data.companyName ?? undefined;
      this.salutation = data.salutation;
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.dateOfBirth = data.dateOfBirth ?? undefined;
      this.email = data.email ?? undefined;
      this.phoneNumber = data.phoneNumber ?? undefined;
      this.mobilePhoneNumber = data.mobilePhoneNumber ?? undefined;
      this.companyPhone = data.companyPhone ?? undefined;
      this.street = data.street ?? undefined;
      this.houseNumber = data.houseNumber ?? undefined;
      this.addressLine1 = data.addressLine1 ?? undefined;
      this.addressLine2 = data.addressLine2 ?? undefined;
      this.poBox = data.poBox ?? undefined;
      this.postalCode = data.postalCode ?? undefined;
      this.postalCodeAddition = data.postalCodeAddition ?? undefined;
      this.city = data.city ?? undefined;
      this.countryIso2 = data.countryIso2 ?? undefined;
      this.website = data.website ?? undefined;
    }
  }
  /**
  */
  isCompany?: boolean;
  /**
  */
  companyName?: string;
  /**
  * @type {int32}
  */
  salutation?: 0 | 1 | 2 | 3; // PersonSalutationDTO
  /**
  */
  firstName?: string;
  /**
  */
  lastName?: string;
  /**
  * @type {date-time}
  */
  dateOfBirth?: string;
  /**
  */
  email?: string;
  /**
  */
  phoneNumber?: string;
  /**
  */
  mobilePhoneNumber?: string;
  /**
  */
  companyPhone?: string;
  /**
  */
  street?: string;
  /**
  */
  houseNumber?: string;
  /**
  */
  addressLine1?: string;
  /**
  */
  addressLine2?: string;
  /**
  */
  poBox?: string;
  /**
  */
  postalCode?: string;
  /**
  */
  postalCodeAddition?: string;
  /**
  */
  city?: string;
  /**
  */
  countryIso2?: string;
  /**
  */
  website?: string;

  static toModel(dto: DeepPartial<ContactAddressDTO>): ContactAddressModel;
  static toModel(dto: DeepPartial<ContactAddressDTO> | undefined | null): ContactAddressModel | undefined;
  static toModel(dto: DeepPartial<ContactAddressDTO> | undefined | null): ContactAddressModel | undefined {
    return dto ? new ContactAddressModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ContactAddressModel> | ContactAddressModel): ContactAddressDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      isCompany: unwrapped.isCompany,
      companyName: unwrapped.companyName,
      salutation: unwrapped.salutation,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      dateOfBirth: unwrapped.dateOfBirth,
      email: unwrapped.email,
      phoneNumber: unwrapped.phoneNumber,
      mobilePhoneNumber: unwrapped.mobilePhoneNumber,
      companyPhone: unwrapped.companyPhone,
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      addressLine1: unwrapped.addressLine1,
      addressLine2: unwrapped.addressLine2,
      poBox: unwrapped.poBox,
      postalCode: unwrapped.postalCode,
      postalCodeAddition: unwrapped.postalCodeAddition,
      city: unwrapped.city,
      countryIso2: unwrapped.countryIso2,
      website: unwrapped.website,
    } as ContactAddressDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ContactAddressModel, can not map to ContactAddressDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
