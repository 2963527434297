import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import WidgetMembershipTemplatesApi from '../generated/api/WidgetMembershipTemplates';

export default (service: Servicelayer) => ({
  ...WidgetMembershipTemplatesApi(service),

  // Add custom calls here

});
