import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import Vue from 'vue';

export default {
  ...queryBuilder.properties({
    // Validated by hand in the first step - multiple radio-buttons break validation at the moment
    // membershipTemplateId: { required: true },
  }),
};
