<template>
  <prona-wizard-step class="wizard-step-membership-order-overview" :proceed-label="proceedLabel" :custom-proceed-function="customProceed">
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div v-for="section in wizardData.summary.sections" :key="section.title" class="summary_tables">
      <strong>{{ section.title }}</strong>
      <table>
        <tr v-for="entry in section.items" :key="entry.label">
          <td v-if="$media.isNotMobile">
            {{ entry.label }}
          </td>
          <td>
            <span v-if="entry.showLabelOnMobile && $media.isMobile">{{ entry.label }}: </span>
            {{ entry.displayValue }}
          </td>
        </tr>
      </table>
    </div>
    <p-checkbox v-model="wizardData.acceptGdpr" :label="$tEnum('pronatura.widget.membershipwizard.accept_terms', 'no_html')">
      <span v-html="$tEnum('pronatura.widget.membershipwizard.accept_terms', 'html')"></span>
    </p-checkbox>
  </prona-wizard-step>
</template>

<script lang="ts">
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import MembershipTemplateResourceViewModel from '@/src/services/v1/viewModel/resource/MembershipTemplateResourceViewModel';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'WizardStepMembershipOrderOverview',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      selectedMembership: undefined as undefined |MembershipTemplateResourceViewModel,
    }),
    computed: {
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.value ?? {};
        },
        set(value: CreateMembershipRequestViewModel) {
          this.$emit('input', value);
        },
      },
      proceedLabel(): string {
        return this.wizardData.paymentMethod === 6 // 6: Online Payment
          ? this.$t('pronatura.widget.membershipwizard.continue_and_pay')
          : this.$t('pronatura.widget.membershipwizard.complete_membership');
      },
    },
    async beforeMount() {
      if (this._.isSet(this.wizardData.membershipTemplateId)) {
        const selectedMembershipRequest = await this.$service.api.widgetMembershipTemplates.getMembershipTemplateById(this.wizardData.membershipTemplateId);
        this.selectedMembership = selectedMembershipRequest;

        if (this._.isSet(this.wizardData.membershipAmount) && this._.isSet(this.selectedMembership.minimumAnnualFee)) {
          if (this.wizardData.membershipAmount < this.selectedMembership.minimumAnnualFee || !Number(this.wizardData.membershipAmount)) {
            this.wizardData.membershipAmount = this.selectedMembership.minimumAnnualFee;
          }
        } else {
          this.wizardData.membershipAmount = this.selectedMembership.minimumAnnualFee;
        }
      }
    },
    async mounted() {
      // add WidgetAnalyticsData
      const WidgetWindow = window as any;
      WidgetWindow.glitWidget = {};
      WidgetWindow.glitWidget.analytics = {};
      WidgetWindow.glitWidget.analytics.transactionId = new Date().getTime();
      WidgetWindow.glitWidget.analytics.paymentMethod = this.wizardData.paymentMethod! > 1 ? 'kk' : 'chqr';
      WidgetWindow.glitWidget.analytics.membership = {};
      WidgetWindow.glitWidget.analytics.membership.id = this.wizardData.membershipTemplateId;
      WidgetWindow.glitWidget.analytics.membership.name = this.wizardData.chosenMembershipCaption;
      WidgetWindow.glitWidget.analytics.membership.amount = this.wizardData.membershipAmount;
      this.$sessionStorage.set('widget-analytics-window', WidgetWindow.glitWidget);
    },
    methods: {
      customProceed() {
        this.$sessionStorage.set('create-transaction', true);
        this.proceedFunction!();
      },
    },
  });
</script>
