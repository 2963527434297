<template>
  <p-app theme="glittr">
    <p-container>
      <slot></slot>
    </p-container>
  </p-app>
</template>

<script>
  export default {
    data: () => ({
    }),
  };
</script>
