import Vue from 'vue';
import AnimaturaRegistrationRequestModel from '../../generated/types/model/resource/AnimaturaRegistrationRequestModel';
import RequestSummaryResourceViewModel from './RequestSummaryResourceViewModel';
import RequestSummaryItemResourceViewModel from './RequestSummaryItemResourceViewModel';
import RequestSummarySectionResourceViewModel from './RequestSummarySectionResourceViewModel';
import AnimaturaTopicResourceViewModel from './AnimaturaTopicResourceViewModel';
import GuidLookupResourceViewModel from './GuidLookupResourceViewModel';

export default class AnimaturaRegistrationRequestViewModel extends AnimaturaRegistrationRequestModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs
  animationLanguages: Array<GuidLookupResourceViewModel> = [];

  translatedAnimationLanguages: Array<GuidLookupResourceViewModel> = [];

  translatedLanguages: Array<GuidLookupResourceViewModel> = [];

  async loadLanguages() {
    this.animationLanguages = (await Vue.$service.v1.api.widgetAnimatura.lookupLanguages()).items.map((l) => { return { id: l.id, caption: Vue.$t(`core.page.language.${l.caption?.toLowerCase()}`) } as GuidLookupResourceViewModel; });
    this.translatedAnimationLanguages = (await Vue.$service.v1.api.widgetAnimatura.lookupLanguages()).items.map((l) => { return { id: l.caption, caption: Vue.$t(`core.page.language.${l.caption!.toLowerCase()}`) } as GuidLookupResourceViewModel; });
    this.translatedLanguages = (['DE', 'FR']).map((l) => { return { id: l, caption: Vue.$t(`core.page.language.${l.toLowerCase()}`) } as GuidLookupResourceViewModel; });
  }

  protected transformToDTO() {
    this.dto.summary = this.summary;
    (this.dto as any).selectedAnimaturaTopic = this.selectedAnimaturaTopic?.toDTO();
  }

  protected transformFromDTO() {
    this.selectedAnimaturaTopic = this.selectedAnimaturaTopic ?? new AnimaturaTopicResourceViewModel();
    const dto = this.dto as any;
    this.selectedAnimaturaTopic.fromDTO(dto?.selectedAnimaturaTopic ?? {});
  }

  get animaturaTopicKeyFromQuery() { return this.getFromAttributeOrQuery<number>('animaturatopickey'); }

  selectedAnimaturaTopic?: AnimaturaTopicResourceViewModel;

  getFromAttributeOrQuery<T>(key: string): T | undefined {
    key = key.toLowerCase();
    const queries = Vue.$routerUtils.getQueryParams();
    const queriesLowerCase = {} as any;
    Object.keys(queries).forEach((queryKey) => {
      queriesLowerCase[queryKey.toLowerCase()] = queries[queryKey];
    });
    const configKey = `widget-${key}`;
    const value = (Vue.$config.values as any)[configKey] ?? Vue.$router.currentRoute.params[key] ?? queriesLowerCase[key];
    return value;
  }

  get summary():RequestSummaryResourceViewModel {
    return new RequestSummaryResourceViewModel().fromModel({
      sections: [
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.animaturawizard.summary.animation'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.selectedAnimaturaTopic?.title,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.animation.topic'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.animationPlace,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.animation.location'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.desiredDates,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.animation.desireddates'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.animationLanguages?.filter((l) => l.id === this.animationLanguageId)[0]?.caption,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.animation.language'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.schoolClass,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.animation.class'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.countParticipants,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.animation.participants'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.animationNotes,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.animation.notes'),
              showLabelOnMobile: true,
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.animaturawizard.summary.teacher.title'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.teacherContact.address.salutation === 3 ? '' : Vue.$t(`core.address.salutationEnum${this.teacherContact.address.salutation}`),
              label: Vue.$t('core.address.salutation'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.teacherContact.address.firstName,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.teacher.firstName'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.teacherContact.address.lastName,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.teacher.lastName'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.translatedLanguages?.filter((l) => l.id === this.teacherContact.languageIso2)[0]?.caption,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.teacher.correspondanceLanguage'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.teacherContact.address.phoneNumber,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.teacher.phonenumber'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.teacherContact.address.eMail,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.teacher.email'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.acceptMarketing ? Vue.$t('core.app.yes') : Vue.$t('core.app.no'),
              label: Vue.$t('pronatura.widget.animaturawizard.summary.teacher.email-acceptance'),
              showLabelOnMobile: true,
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.animaturawizard.summary.schooladdress.title'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.schoolContact.address?.companyName,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.schooladdress.school'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: `${this.schoolContact.address?.street} ${this.schoolContact.address?.houseNumber}`,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.schooladdress.street'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: `${this.schoolContact.address?.postalCode} ${this.schoolContact.address?.city}`,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.schooladdress.city'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.schoolContact.address?.phoneNumber,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.schooladdress.phonenumber'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingAddress?.isEqualToSchoolAddress ? Vue.$t('core.app.yes') : Vue.$t('core.app.no'),
              label: Vue.$t('pronatura.widget.animaturawizard.summary.schooladdress.isBillingAddress'),
              showLabelOnMobile: true,
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.animaturawizard.summary.billingaddress.title'),
          items: this.billingAddress?.isEqualToSchoolAddress ? [] : [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingAddress?.address?.companyName,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.billingaddress.company'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: `${this.billingAddress?.address?.street} ${this.billingAddress?.address?.houseNumber}`,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.billingaddress.street'),
              showLabelOnMobile: true,
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: `${this.billingAddress?.address?.postalCode} ${this.billingAddress?.address?.city}`,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.billingaddress.city'),
            }),
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingAddress?.address?.eMail,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.billingaddress.eMail'),
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
        new RequestSummarySectionResourceViewModel().fromModel({
          title: Vue.$t('pronatura.widget.animaturawizard.summary.invoicereference.title'),
          items: [
            new RequestSummaryItemResourceViewModel().fromModel({
              displayValue: this.billingAddress?.billingAccount,
              label: Vue.$t('pronatura.widget.animaturawizard.summary.invoicereference.accountassignment'),
            }),
          ].filter((i) => Vue._.isSet(i.displayValue)),
        }),
      ].filter((i) => !Vue._.isEmpty(i.items)),
    });
  }
}
