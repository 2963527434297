import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';

export default {
  ...queryBuilder.properties({
    memberContact: {
      type: 'object',
      properties: {
        address: {
          type: 'object',
          properties: {
            salutation: {
              required: true, type: 'integer',
            },
            firstName: {
              required: true, type: 'string',
            },
            lastName: {
              required: true, type: 'string',
            },
            street: {
              required: true, type: 'string',
            },
            postalCode: {
              required: true, type: 'string',
            },
            city: {
              required: true, type: 'string',
            },
          },
        },
      },
    },
  }),
  if: {
    properties: {
      isMemberContactDateOfBirthRequired: {
        const: true,
      },
    },
  },
  then: {
    ...queryBuilder.properties({
      memberContact: {
        type: 'object',
        properties: {
          address: {
            type: 'object',
            properties: {
              dateOfBirth: {
                type: 'string',
                required: true,
              },
            },
          },
        },
      },
    }),
  },
};
