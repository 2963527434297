<template>
  <prona-wizard
    ref="wizard"
    class="wizard-membership-registration"
    :title="title"
    :steps="steps"
    :initialize-data="initializeData"
    :service-function="serviceFunction"
    :model-class="model"
    to-on-complete="/"
  ></prona-wizard>
</template>

<script lang="ts">
  import Wizard, { StepData } from '@/src/components/wizard/prona-wizard.vue';
  import ContactAddressResourceViewModel from '@/src/services/v1/viewModel/resource/ContactAddressResourceViewModel';
  import ContactResourceViewModel from '@/src/services/v1/viewModel/resource/ContactResourceViewModel';
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import RequestSummaryResourceViewModel from '@/src/services/v1/viewModel/resource/RequestSummaryResourceViewModel';

  import Vue from 'vue';
  import PaymentMethodResourceDTO from '@/src/services/v1/generated/types/dto/resource/PaymentMethodResourceDTO';
  import oneSchema from './1.schema';
  import twoSchema from './2.schema';
  import threeSchema from './3.schema';
  import fourSchema from './4.schema';
  import fiveSchema from './5.schema';
  import sixSchema from './6.schema';

  type WizardDataType = InstanceType<typeof Wizard>;

  export default Vue.extend({
    name: 'WizardMembershipRegistration',
    layout: 'layout-widget',
    props: {

    },
    data: () => ({
      steps: [] as StepData[],
      title: Vue.$t('pronatura.widget.membershipwizard.wizard_title'),
      membershipIdKey: 'membershipId',
      model: CreateMembershipRequestViewModel,
    }),
    computed: {

    },
    mounted() {
      this.steps = [
        {
          breadcrumb: 'pronatura.widget.membershipwizard.membership_title',
          component: () => import('./1.membership-category.vue'),
          schema: oneSchema,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          // condition: (wizardData: CreateMembershipRequestViewModel) => !this._.isSet(wizardData.membershipTemplateId),
        },
        {
          breadcrumb: 'pronatura.widget.membershipwizard.info_title',
          component: () => import('./2.membership-info.vue'),
          schema: twoSchema,
          title: (wizardData: CreateMembershipRequestViewModel) => (wizardData.chosenMembershipCaption && wizardData.chosenMembershipCaption !== 'undefined' ? wizardData.chosenMembershipCaption : ''),
          // schema: oneSchema,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          // condition: (wizardData: CreateMembershipRequestViewModel) => wizardData.acceptGdpr ?? false,
        },
        {
          breadcrumb: 'pronatura.widget.membershipwizard.customer_title',
          component: () => import('./3.membership-contactdata.vue'),
          schema: threeSchema,
          // schema: oneSchema,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          // condition: () => this._.isSet(this.orderTemplateId),
          // disablePreviousSteps: true,
        },
        {
          breadcrumb: 'pronatura.widget.membershipwizard.donee_title',
          component: () => import('./4.membership-gift-contactdata.vue'),
          schema: fourSchema,
          // schema: oneSchema,
          // clearProgressAfterSend: false / true,
          // sendData: false / true
          condition: (wizardData: CreateMembershipRequestViewModel) => wizardData.isGiftMembership ?? false,
          // disablePreviousSteps: true,
        },
        {
          breadcrumb: 'pronatura.widget.membershipwizard.customer2_title',
          component: () => import('./5.membership-partner-contactdata.vue'),
          schema: fiveSchema,
          title: `${Vue.$t('pronatura.widget.membershipwizard.customer2_title')} (${Vue.$t('pronatura.widget.membershipwizard.customer2_optional')})`,
          condition: (wizardData: CreateMembershipRequestViewModel) => !!wizardData.chosenMembershipTemplate?.hasPartnerAddress,
        },
        {
          breadcrumb: 'pronatura.widget.membershipwizard.order_overview',
          component: () => import('./6.membership-order-overview.vue'),
          sendData: (wizardData: CreateMembershipRequestViewModel) => wizardData.paymentMethod === 1, // 1: Invoice
          schema: sixSchema,
          // clearProgressAfterSend: true,
        },
        {
          breadcrumb: 'pronatura.widget.membershipwizard.online_payment_title',
          component: () => import('./7.membership-online-payment.vue'),
          sendData: true,
          condition: (wizardData: CreateMembershipRequestViewModel) => wizardData.paymentMethod === 6, // 6: Online Payment
          disablePreviousSteps: (wizardData: CreateMembershipRequestViewModel) => !!wizardData.onlinePaymentTransactionId,
        },
        {
          breadcrumb: 'pronatura.widget.membershipwizard.order_confirmation',
          component: () => import('./8.membership-order-complete.vue'),
          disablePreviousSteps: true,
          clearProgressOnMounted: true,
        },
      ];
      const wizard = this.$refs.wizard as WizardDataType;
      wizard.$once('initialized', async (wizardData: CreateMembershipRequestViewModel) => {
        const membershipId = wizardData.membershipIdFromQuery;
        const membershipAmount = wizardData.membershipAmountFromQuery;
        const paymentMethod = wizardData.paymentMethodFromQuery;
        const isGift = wizardData.isGiftFromQuery;
        // If any of the possible values is given via url, we reset the wizard, preset the data and continue on the step that matches the information given
        if ((this._.isSet(membershipId)) || (this._.isSet(membershipAmount)) || (this._.isSet(paymentMethod)) || (this._.isSet(isGift))) {
          await wizard.clearProgress();

          const currentQuery = this.$routerUtils.getQueryParams();
          if (this._.isSet(membershipId)) {
            wizardData.membershipTemplateId = membershipId;
            delete currentQuery.membershipid;
            delete this.$route.query.membershipid;

            if (this._.isSet(membershipAmount)) {
              wizardData.membershipAmount = await this.getValidatedMinimalAmount(membershipId, membershipAmount);
              delete currentQuery.membershipamount;
              delete this.$route.query.membershipamount;
            }
            if (this._.isSet(paymentMethod)) {
              wizardData.paymentMethod ??= paymentMethod as PaymentMethodResourceDTO;
              delete currentQuery.paymentMethod;
              delete this.$route.query.paymentMethod;
            }
            if (this._.isSet(isGift)) {
              wizardData.isGiftMembership = isGift.toString() === 'true';
              delete currentQuery.isGift;
              delete this.$route.query.isGift;
            }
          }

          this.$routerUtils.updateQueryParams(currentQuery, { preserve: false });
          wizard.saveWizardData();

          let nextStep = 0;
          if ((this._.isSet(membershipId)) && (this._.isSet(membershipAmount)) && (this._.isSet(paymentMethod)) && (this._.isSet(isGift))) {
            nextStep = 2; // all data for page two is available
          } else if (this._.isSet(membershipId)) {
            nextStep = 1; // all data for page one is available
          }
          wizard.setStep(nextStep);
        }
      });
    },
    methods: {
      async serviceFunction(wizardData: CreateMembershipRequestViewModel) {
        if (!wizardData.chosenMembershipTemplate?.hasPartnerAddress || !wizardData?.partnerContact?.hasContactAddressValue) {
          (wizardData as any).partnerContact = undefined;
        }

        if (!wizardData.isGiftMembership) {
          (wizardData as any).memberContact = undefined;
        }

        await this.$service.v1.api.widgetMemberships.createMembership(wizardData);
      },
      initializeData() {
        // const language = this.$translation.get()?.toLowerCase() ?? this.$config.values['language-default']?.toLowerCase();
        const initializedModel = new CreateMembershipRequestViewModel().fromModel({
          billingContact: new ContactResourceViewModel().fromModel({
            address: new ContactAddressResourceViewModel(),
          }),
          memberContact: new ContactResourceViewModel().fromModel({
            address: new ContactAddressResourceViewModel(),
          }),
          partnerContact: new ContactResourceViewModel().fromModel({
            address: new ContactAddressResourceViewModel(),
          }),
          summary: new RequestSummaryResourceViewModel(),
        });

        const userLanguage = initializedModel.userLanguageFromQuery ?? this.$translation.get()?.toLowerCase();
        initializedModel.languageIso2 = userLanguage;

        // TODO: https://glittrsoftware.visualstudio.com/ProNatura.IL/_workitems/edit/7590 Remove hardcoded defaults when values are set correctly in membership process
        const recruitingBusinessUnitNumber = initializedModel.recruitingBusinessUnitNumberFromQuery ?? undefined;
        initializedModel.recruitingBusinessUnitNumber = recruitingBusinessUnitNumber;

        const admissionReasonNumber = initializedModel.admissionReasonNumberFromQuery ?? this.$config.values['admission-motivation-reason-number-default'];
        initializedModel.admissionReasonNumber = admissionReasonNumber;
        const admissionMotivationReasonNumber = initializedModel.admissionMotivationReasonNumberFromQuery ?? this.$config.values['admission-motivation-reason-number-default'];
        initializedModel.admissionMotivationReasonNumber = admissionMotivationReasonNumber;
        const sourceCampaignNumber = initializedModel.sourceCampaignNumberFromQuery ?? this.$config.values['source-campaign-number-default'];
        initializedModel.sourceCampaignNumber = sourceCampaignNumber;
        return initializedModel;
      },
      // this method loads the minimalAnnualFee from the membership and returns the amount or minimalAnnualFee whatever is the bigger amount
      // this prevents fraud when providing a lower amount in the querystring
      async getValidatedMinimalAmount(membershipTemplateId: string, amount: number): Promise<number> {
        const selectedMembershipRequest = await this.$service.api.widgetMembershipTemplates.getMembershipTemplateById(membershipTemplateId);
        let minimumFee = 0;
        if (this._.isSet(selectedMembershipRequest.minimumAnnualFee)) minimumFee = selectedMembershipRequest.minimumAnnualFee;
        if (minimumFee < amount) return amount;
        return minimumFee;
      },
    },
  });
</script>
