import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  properties: {
    partnerContact: {
      ...queryBuilder.if({ hasContactAddressValue: true }),
      then: {
        ...queryBuilder.properties({
          address: {
            type: 'object',
            properties: {
              salutation: { type: 'integer', required: true },
              firstName: { type: 'string', required: true },
              lastName: { type: 'string', required: true },
              dateOfBirth: { type: 'string' },
              eMail: { type: 'string', pattern: validationPatterns.email },
            },
          },
        }),
      },
    },
  },
} as IJSONSchema;
