<template>
  <prona-wizard-step class="wizard-step-membership-info" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <template v-if="selectedMembership">
      <p>{{ $t('pronatura.widget.membershipwizard.info_description', {fee: selectedMembership.minimumAnnualFee, membershipType: selectedMembership.caption}) }}</p>
      <p-number-field
        v-model="wizardData.membershipAmount"
        :label="`${$t('pronatura.widget.membershipwizard.amount_title')} (CHF)`"
        class="ix-6"
        :step="10"
        :min="0"
        @blur="validate()"
        @add-clicked="onAddClicked()"
      >
      </p-number-field>
      <p-checkbox v-if="selectedMembership.isGiftMembershipAvailable" v-model="wizardData.isGiftMembership" :label="$t('pronatura.widget.membershipwizard.gift_yes')">
      </p-checkbox>
      <strong class="mt-4">{{ $t('pronatura.widget.membershipwizard.payment_title') }}</strong>
      <p-row>
        <p-radio-field
          v-model="wizardData.paymentMethod"
          :true-value="1"
          name="payment-option"
          :label="$t('pronatura.widget.membershipwizard.payment_invoice')"
        ></p-radio-field>
        <p-radio-field
          v-model="wizardData.paymentMethod"
          :true-value="6"
          name="payment-option"
          :label="$t('pronatura.widget.membershipwizard.payment_online')"
        ></p-radio-field>
      </p-row>
      <p>
        {{
          wizardData.paymentMethod === 1
            ? $t('pronatura.widget.membershipwizard.payment_invoice_additional_info')
            : $t('pronatura.widget.membershipwizard.payment_online_additional_info')
        }}
      </p>
    </template>
  </prona-wizard-step>
</template>

<script lang="ts">
  import CreateMembershipRequestViewModel from '@/src/services/v1/viewModel/resource/CreateMembershipRequestViewModel';
  import MembershipTemplateResourceViewModel from '@/src/services/v1/viewModel/resource/MembershipTemplateResourceViewModel';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'WizardStepMembershipInfo',
    props: {
      value: { type: Object, default: undefined },
      backFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
      selectedMembership: undefined as undefined |MembershipTemplateResourceViewModel,
    }),
    computed: {
      wizardData: {
        get(): CreateMembershipRequestViewModel {
          return this.value ?? {};
        },
        set(value: CreateMembershipRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      try {
        this.$set(this.wizardData, 'chosenMembershipTemplate', undefined);
        if (this._.isSet(this.wizardData.membershipTemplateId)) {
          const selectedMembershipRequest = await this.$service.api.widgetMembershipTemplates.getMembershipTemplateById(this.wizardData.membershipTemplateId);
          this.selectedMembership = selectedMembershipRequest;
        }
        if (!this._.isSet(this.selectedMembership)) {
          throw new Error('Keine Mitgliedschaft ausgewählt');
        }

        this.$set(this.wizardData, 'chosenMembershipTemplate', this.selectedMembership);
      } catch (error: any) {
        this.error = error;
        this.$log.error(error);
      }

      this.wizardData.paymentMethod ??= 1; // 1: Invoice

      this.isLoading = false;
    },
    methods: {
      validate(): boolean {
        if (!this._.isSet(this.selectedMembership) || !this._.isSet(this.selectedMembership.minimumAnnualFee)) {
          return false;
        }

        if (!this._.isSet(this.wizardData.membershipAmount) || !Number(this.wizardData.membershipAmount) || this.wizardData.membershipAmount < this.selectedMembership.minimumAnnualFee) {
          // wenn keine Eingabe oder wenn Eingabe kleiner als Mindestbetrag Fehler
          this.validationError = this.$t('pronatura.widget.membershipwizard.amount_error', { fee: this.selectedMembership.minimumAnnualFee });
          return false;
        }

        if (this.wizardData.membershipAmount <= 0) {
          this.wizardData.membershipAmount = 0;
        }

        this.validationError = undefined;
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.proceedFunction!();
        }
      },
      onAddClicked() {
        if (this._.isSet(this.wizardData.membershipAmount) && this._.isSet(this.selectedMembership?.minimumAnnualFee)) {
          if (this.wizardData.membershipAmount < this.selectedMembership?.minimumAnnualFee!) {
            this.wizardData.membershipAmount = this.selectedMembership?.minimumAnnualFee;
          }
        }
      },
    },
  });
</script>
