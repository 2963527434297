import Vue from 'vue';
import CreateAnimaturaRegistrationRequestModelBase from '../_generated/modelBases/create-animatura-registration-request-model-base';

export default class CreateAnimaturaRegistrationRequestModel extends CreateAnimaturaRegistrationRequestModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  get animaturaTopicIdFromQuery() { return this.getFromAttributeOrQuery('animaturatopicid'); }

  getFromAttributeOrQuery(key: string): string | undefined {
    key = key.toLowerCase();
    const queries = Vue.$routerUtils.getQueryParams();
    const queriesLowerCase = {} as any;
    Object.keys(queries).forEach((queryKey) => {
      queriesLowerCase[queryKey.toLowerCase()] = queries[queryKey];
    });
    const configKey = `widget-${key}`;
    const value = (Vue.$config.values as any)[configKey] ?? Vue.$router.currentRoute.params[key] ?? queriesLowerCase[key];
    return value;
  }
}
