<template>
  <prona-wizard-step class="wizard-step-animatura-topic" :custom-proceed-function="customProceed" :errors="validationError">
    <p-request-alert v-model="error"></p-request-alert>
    <p-progress-linear v-if="isLoading" class="my-5" indeterminate></p-progress-linear>
    <div class="animatura-topic-selection" :class="classList">
      <p-row>
        <p-col
          v-for="topic in topics"
          :key="topic.topicKey"
          xs12
          md6
          lg4
        >
          <p-radio-field v-model="wizardData.animationTopicKey" :true-value="topic.topicKey" name="animatura-topic" :label="topic.title" @change="onTopicSelect($event, topic)"></p-radio-field>
          <!-- <p class="caption-1">
            <span>
              {{ topic.description }}
            </span>
          </p> -->
        </p-col>
      </p-row>
    </div>
  </prona-wizard-step>
</template>

<script lang="ts">
  import { PropType } from 'vue';
  import mixins from '@glittr/frontend-core/src/mixins';
  import validatable from '@glittr/frontend-core/src/mixins/validatable/validatable';
  import AnimaturaTopicResourceViewModel from '@/src/services/v1/viewModel/resource/AnimaturaTopicResourceViewModel';
  import GetAnimaturaTopicsRequestViewModel from '@/src/services/v1/viewModel/request/WidgetAnimaturaTopics/GetAnimaturaTopicsRequestViewModel';
  import AnimaturaRegistrationRequestViewModel from '@/src/services/v1/viewModel/resource/AnimaturaRegistrationRequestViewModel';

  export default mixins(validatable).extend({
    name: 'WizardStepAnimaturaTopic',
    props: {
      value: { type: Object, default: undefined },
      proceedFunction: { type: Function as PropType<(()=>void)>, default: () => {} },
      clearProgressFunction: { type: Function as PropType<(()=>Promise<void>)>, default: () => {} },
    },
    data: () => ({
      isLoading: false,
      error: undefined as any,
      validationError: undefined as string | undefined,
      topics: [] as AnimaturaTopicResourceViewModel[],
    }),
    watch: {
      'wizardData.animationTopicKey': {
        handler() {
          if (this._.isSet(this.wizardData.animationTopicKey)) {
            this.validationError = undefined;
          }
        },
      },
    },
    computed: {
      classList(): Record<string, boolean> {
        return {
          'input-error': this._.isSet(this.validationError),
        };
      },
      wizardData: {
        get(): AnimaturaRegistrationRequestViewModel {
          return this.value ?? {};
        },
        set(value: AnimaturaRegistrationRequestViewModel) {
          this.$emit('input', value);
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      try {
        const req = new GetAnimaturaTopicsRequestViewModel();
        const result = await this.$service.api.widgetAnimaturaTopics.getAnimaturaTopics(req);
        if (this.wizardData.isAdminMode) this.topics = result.items;
        else this.topics = result.items.filter((i) => i.isActive === true);
        if (this._.isSet(this.wizardData.animationTopicKey)) {
          const selectedTopic = await this.$service.v1.api.widgetAnimaturaTopics.getAnimaturaTopicById(this.wizardData.animationTopicKey);
          this.wizardData.selectedAnimaturaTopic = selectedTopic;
        }
      } catch (error: any) {
        this.error = error;
        this.$log.error(error);
      }
      this.isLoading = false;
    },
    methods: {
      validate(): boolean {
        if (!this._.isSet(this.wizardData.animationTopicKey)) {
          this.validationError = this.$t('pronatura.widget.animaturawizard.topicNotSelected');
          return false;
        }
        return true;
      },
      customProceed() {
        if (this.validate()) {
          this.proceedFunction!();
        }
      },
      async onTopicSelect(id: any, topic: AnimaturaTopicResourceViewModel) {
        await this.clearProgressFunction!();
        this.wizardData.animationTopicKey = topic.topicKey!;
        this.$set(this.wizardData, 'selectedAnimaturaTopic', topic);
      },
    },
  });
</script>
