import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/_widgets/membership/registration',
      name: 'membership-registration',
      component: () => import('./views/widgets/membership/registration/wizard.vue'),
    },
    {
      path: '/_admin/testing/membership/registration',
      name: 'membership-registration-config',
      component: () => import('./views/pages/_admin/testing/widgets/membership-registration-config/membership-registration-config.vue'),
    },
    {
      path: '/_widgets/animatura/registration',
      name: 'animatura-registration',
      component: () => import('./views/widgets/animatura/registration/wizard.vue'),
    },
    {
      path: '/_admin/testing/animatura/registration',
      name: 'animatura-registration-config',
      component: () => import('./views/pages/_admin/testing/widgets/animatura-registration-config/animatura-registration-config.vue'),
    },
    {
      path: '/_widgets/animatura/registration-internal',
      name: 'animatura-registration-internal',
      component: () => import('./views/widgets/animatura/registration/wizard-internal.vue'),
    },

    {
      path: '/_admin/recurring-membership-payments',
      name: 'recurring-membership-payments',
      component: () => import('./views/pages/app/admin/recurring-membership-payments.vue'),
    },
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },

  ],
};
