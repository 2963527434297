// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CreateAnimaturaRegistrationRequestModel from '../../model/create-animatura-registration-request-model';
import CreateAnimaturaRegistrationRequestDTO from '../dto/create-animatura-registration-request-dto';
import dtoSchema from '../schemas/create-animatura-registration-request-schema';
import ContactModel from '../../model/contact-model';
import BillingAddressModel from '../../model/billing-address-model';
import RequestSummaryModel from '../../model/request-summary-model';

export default abstract class CreateAnimaturaRegistrationRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CreateAnimaturaRegistrationRequestDTO>) {
    super();
    if (data) {
      this.isAdminMode = data.isAdminMode;
      this.animationTopicKey = data.animationTopicKey;
      this.desiredDates = data.desiredDates ?? undefined;
      this.animationLanguageId = data.animationLanguageId;
      this.animationPlace = data.animationPlace ?? undefined;
      this.schoolClass = data.schoolClass ?? undefined;
      this.countParticipants = data.countParticipants ?? undefined;
      this.animationNotes = data.animationNotes ?? undefined;
      this.teacherContact = data.teacherContact ? ContactModel.toModel(data.teacherContact) : undefined;
      this.acceptMarketing = data.acceptMarketing;
      this.schoolContact = data.schoolContact ? ContactModel.toModel(data.schoolContact) : undefined;
      this.billingAddress = data.billingAddress ? BillingAddressModel.toModel(data.billingAddress) : undefined;
      this.summary = data.summary ? RequestSummaryModel.toModel(data.summary) : undefined;
      this.acceptGdpr = data.acceptGdpr;
    }
  }
  /**
  */
  isAdminMode?: boolean;
  /**
  * @type {int32}
  */
  animationTopicKey?: number;
  /**
  */
  desiredDates?: string;
  /**
  * @type {uuid}
  */
  animationLanguageId?: string;
  /**
  */
  animationPlace?: string;
  /**
  */
  schoolClass?: string;
  /**
  */
  countParticipants?: string;
  /**
  */
  animationNotes?: string;
  /**
  */
  teacherContact?: ContactModel;
  /**
  */
  acceptMarketing?: boolean;
  /**
  */
  schoolContact?: ContactModel;
  /**
  */
  billingAddress?: BillingAddressModel;
  /**
  */
  summary?: RequestSummaryModel;
  /**
  */
  acceptGdpr?: boolean;

  static toModel(dto: DeepPartial<CreateAnimaturaRegistrationRequestDTO>): CreateAnimaturaRegistrationRequestModel;
  static toModel(dto: DeepPartial<CreateAnimaturaRegistrationRequestDTO> | undefined | null): CreateAnimaturaRegistrationRequestModel | undefined;
  static toModel(dto: DeepPartial<CreateAnimaturaRegistrationRequestDTO> | undefined | null): CreateAnimaturaRegistrationRequestModel | undefined {
    return dto ? new CreateAnimaturaRegistrationRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CreateAnimaturaRegistrationRequestModel> | CreateAnimaturaRegistrationRequestModel): CreateAnimaturaRegistrationRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      isAdminMode: unwrapped.isAdminMode,
      animationTopicKey: unwrapped.animationTopicKey,
      desiredDates: unwrapped.desiredDates,
      animationLanguageId: unwrapped.animationLanguageId,
      animationPlace: unwrapped.animationPlace,
      schoolClass: unwrapped.schoolClass,
      countParticipants: unwrapped.countParticipants,
      animationNotes: unwrapped.animationNotes,
      teacherContact: unwrapped.teacherContact,
      acceptMarketing: unwrapped.acceptMarketing,
      schoolContact: unwrapped.schoolContact,
      billingAddress: unwrapped.billingAddress,
      summary: unwrapped.summary,
      acceptGdpr: unwrapped.acceptGdpr,
    } as CreateAnimaturaRegistrationRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CreateAnimaturaRegistrationRequestModel, can not map to CreateAnimaturaRegistrationRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
